import React, { useCallback, useState } from "react";

import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Box,
  Flex,
  Icon,
  useDisclosure,
  Collapse,
  Badge,
  Skeleton,
  Divider,
  Text,
} from "@chakra-ui/react";
import { useFetchAccount } from "network/Account/fetch";
import { usePmsTransactionCountNotYetSynced } from "network/PmsTransaction/countNotYetSynced";
import { useRutterTransactionCountNotYetSynced } from "network/RutterTransaction/countNotYetSynced";
import { useGetUserPermissions } from "network/User/get";
import Link, { LinkProps } from "next/link";
import { NextRouter, useRouter } from "next/router";
import { rutterPlatformNames } from "pages/accounting";
import { IconType } from "react-icons";
import { BiBookOpen } from "react-icons/bi";
import { BsBank, BsHouses } from "react-icons/bs";
import { FaRegCreditCard } from "react-icons/fa";
import { IoMdPaperPlane } from "react-icons/io";
import {
  IoDocumentOutline,
  IoDocumentText,
  IoPersonCircle,
  IoPeopleSharp,
} from "react-icons/io5";
import { PiCaretDownBold, PiCaretUpBold } from "react-icons/pi";
import { RxDashboard, RxListBullet } from "react-icons/rx";
import { TbBriefcase2 } from "react-icons/tb";
import { Account, HostfiPagePermissions, Product } from "typings/shared";
import { ButtonLogout } from "ui/Buttons";
import { HostFiLogo } from "ui/Nav/HostFiLogo";
import theme from "ui/Themes/default";
import { isProductEnabledForAccount } from "utils/account";

type BadgeData = {
  rutterTransactionsNotYetSynced: number;
  pmsTransactionsNotYetSynced: number;
};

export type Route = {
  pathname: string;
  display: string;
  authorisationProp?: keyof HostfiPagePermissions | null;
  iconSvg?: IconType;
  beta?: boolean;
  navigateToFirstChild?: boolean;
  children?: {
    pathname: string;
    display: string;
    condition?: (account: Account | undefined) => boolean;
    badge?: (data: BadgeData) => number;
  }[];
  icon?: IconType;
  openInNewTab?: boolean;
};

// TODO if you add a new route, make sure to add it to isAuthenticatedRoute
export const featureRoutes: Route[] = [
  {
    pathname: "/dashboard",
    display: "Dashboard",
    authorisationProp: "dashboard_visibility",
    icon: RxDashboard,
  },
  {
    pathname: "/transactions",
    display: "Transactions",
    authorisationProp: "transaction_visibility",
    icon: RxListBullet,
  },
  {
    pathname: "/properties",
    display: "Properties",
    authorisationProp: "property_visibility",
    icon: BsHouses,
  },
  {
    pathname: "/cards",
    display: "Cards",
    authorisationProp: "card_visibility",
    icon: FaRegCreditCard,
  },
  {
    pathname: "/payments",
    display: "Payments",
    authorisationProp: "payments_visibility",
    icon: IoMdPaperPlane,
    navigateToFirstChild: false,
    children: [
      {
        pathname: "/recipients",
        display: "Recipients",
      },
    ],
  },
  {
    display: "Accounts",
    pathname: "/accounts",
    authorisationProp: "subaccount_visibility",
    icon: BsBank,
    navigateToFirstChild: false,
    children: [
      {
        display: "Credit",
        pathname: "/accounts/credit",
        condition: (account) => {
          return account?.stripe_credit_account?.status === "active";
        },
      },
    ],
  },
  {
    display: "Accounting",
    pathname: "/accounting",
    authorisationProp: "accounting",
    icon: BiBookOpen,
    navigateToFirstChild: false,
    children: [
      ...Object.entries(rutterPlatformNames).map(([key, value]) => {
        return {
          display: value === "Quickbooks Desktop" ? "Quickbooks" : value,
          pathname: `/accounting/${key}`,
          condition: (account: Account | undefined) =>
            [key].includes(account?.accounting_platform ?? ""),
          badge: (data: BadgeData) => {
            return data.rutterTransactionsNotYetSynced;
          },
        };
      }),
      {
        display: "Track",
        pathname: "/accounting/track",
        condition: (account) => account?.pms_name === "track",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "Guesty",
        pathname: "/accounting/guesty",
        condition: (account) =>
          account?.pms_name === "guesty" &&
          account?.pms_accounting_enabled === true,
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "Breezeway",
        pathname: "/accounting/breezeway",
        condition: (account) => account?.pms_name === "breezeway",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "Streamline",
        pathname: "/accounting/streamline",
        condition: (account) => account?.pms_name === "streamline",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "OwnerRez",
        pathname: "/accounting/owner_rez",
        condition: (account) => account?.pms_name === "owner_rez",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "Hostaway",
        pathname: "/accounting/hostaway",
        condition: (account) => account?.pms_name === "hostaway",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
      {
        display: "Buildium",
        pathname: "/accounting/buildium",
        condition: (account) => account?.pms_name === "buildium",
        badge: (data) => {
          return data.pmsTransactionsNotYetSynced;
        },
      },
    ],
  },
];

export const userRoutes: Route[] = [
  {
    pathname: "/settings",
    display: "Settings",
    iconSvg: IoPersonCircle,
    authorisationProp: null,
  },
  {
    pathname: "/settings?tab=team",
    display: "Team",
    iconSvg: IoPeopleSharp,
    authorisationProp: null,
  },
  {
    pathname: "/documents",
    display: "Documents",
    iconSvg: IoDocumentOutline,
    authorisationProp: "documents_visibility",
  },
  {
    pathname: "https://gethostfi.com/legal",
    display: "Legal",
    iconSvg: IoDocumentText,
    authorisationProp: null,
    openInNewTab: true,
  },
];

export const isAuthorized = (
  route: Route,
  permissions?: HostfiPagePermissions,
  isAccountingEnabled?: boolean,
) => {
  if (route.pathname === "/accounting" && isAccountingEnabled === false)
    return false;

  return (
    (permissions &&
      route.authorisationProp &&
      (permissions[route.authorisationProp] as number) > 0) ||
    route.authorisationProp === null
  );
};

interface NavLinkProps {
  route: Route;
  currentPathname: NextRouter["pathname"];
  isDesktop: boolean;
  expanded: Record<string, boolean>;
  setExpanded: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

export const LinkWithTarget = ({
  children,
  openInNewTab,
  href,
  ...linkProps
}: {
  children: any;
  openInNewTab?: boolean;
  href: string;
  linkProps?: LinkProps;
}) => {
  return openInNewTab ? (
    <Link href={href} {...linkProps} passHref>
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    </Link>
  ) : (
    <Link href={href} {...linkProps}>
      {children}
    </Link>
  );
};

function NavLink({
  route,
  currentPathname,
  isDesktop = false,
  expanded = {},
  setExpanded,
}: NavLinkProps) {
  const { data: account } = useFetchAccount();
  const curr = currentPathname === route.pathname;

  const visibleChildren = route.children
    ? route.children.filter(
        (child) => !child.condition || child.condition(account),
      )
    : [];

  return isDesktop ? (
    <LinkWithTarget
      key={route.pathname}
      openInNewTab={route.openInNewTab}
      href={
        route.navigateToFirstChild && visibleChildren.length
          ? visibleChildren[0].pathname
          : route.pathname
      }
    >
      <Box
        display="flex"
        w="100%"
        borderRadius="8px"
        _hover={{
          background: "emerald_mint.30",
        }}
        backgroundColor={curr ? "light_gray.250" : "light_gray.200"}
      >
        <Flex
          py={2}
          px={4}
          w="100%"
          fontSize="sm"
          transition="transform 0.15s"
          cursor="pointer"
          fontWeight={curr ? "medium" : "medium"}
          color={curr ? theme.colors.gray[900] : theme.colors.gray[500]}
          borderRadius="8px"
          _hover={{
            background: "emerald_mint.30",
          }}
        >
          <Box display="inline-block" position="relative" w="100%">
            <Box display="flex" alignItems="center" gap={3} w="100%">
              {route.icon && (
                <Box display="flex" alignItems="center">
                  <Icon
                    as={route.icon}
                    w="16px"
                    h="16px"
                    mb="1px"
                    color={
                      curr
                        ? theme.colors.emerald_mint[400]
                        : theme.colors.gray[500]
                    }
                  />
                </Box>
              )}
              {route.display}
            </Box>
            {route.beta ? <sup> Beta </sup> : null}
          </Box>
        </Flex>
        {visibleChildren.length > 0 && (
          <Box
            cursor="pointer"
            ml="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRightRadius="8px"
            py={2}
            px={2}
            _hover={{
              background: "emerald_mint.30",
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              setExpanded({
                ...expanded,
                [route.display]:
                  expanded[route.display] === undefined
                    ? false
                    : !expanded[route.display],
              });
            }}
          >
            {expanded[route.display] !== false ? (
              <PiCaretUpBold color="gray.500" />
            ) : (
              <PiCaretDownBold color="gray.500" />
            )}
          </Box>
        )}
      </Box>
    </LinkWithTarget>
  ) : (
    <Box
      w="100%"
      p={5}
      display="block"
      backgroundColor={curr ? "light_gray.250" : "light_gray.200"}
      color={curr ? theme.colors.gray[900] : theme.colors.gray[500]}
    >
      <LinkWithTarget
        href={
          route.navigateToFirstChild && visibleChildren.length
            ? visibleChildren[0].pathname
            : route.pathname
        }
        openInNewTab={route.openInNewTab}
      >
        {route.display}
      </LinkWithTarget>
    </Box>
  );
}

function ChildNavLink({
  route,
  currentPathname,
  isDesktop = false,
  badge,
}: {
  route: {
    pathname: string;
    display: string;
    openInNewTab?: boolean;
  };
  currentPathname: string;
  isDesktop: boolean;
  badge: number | null;
}) {
  const curr = currentPathname.includes(route.pathname);
  return isDesktop ? (
    <LinkWithTarget
      key={route.pathname}
      href={route.pathname}
      openInNewTab={route.openInNewTab}
    >
      <Flex
        py={2}
        px={2}
        fontSize="sm"
        transition="transform 0.15s"
        cursor="pointer"
        backgroundColor={curr ? "light_gray.250" : "light_gray.200"}
        color={curr ? theme.colors.gray[900] : theme.colors.gray[500]}
        fontWeight={curr ? "medium" : "medium"}
        borderRadius="8px"
        _hover={{
          background: "emerald_mint.30",
        }}
        alignItems="center"
      >
        <Box ml={2} display="inline-block" position="relative">
          {route.display}
        </Box>
        {badge != null && (
          <>
            <Skeleton
              isLoaded={badge !== -1}
              ml="auto"
              borderRadius="1.5rem"
              fontSize="sm"
              endColor="gray.300"
              h="1.5rem"
              w="1.5rem"
            />
            <Badge
              hidden={badge === -1}
              ml="auto"
              variant="solid"
              backgroundColor="emerald_mint.50"
              color="midnight_teal.400"
              fontSize="sm"
              fontWeight="normal"
              borderRadius="1.5rem"
            >
              {badge}
            </Badge>
          </>
        )}
      </Flex>
    </LinkWithTarget>
  ) : (
    <Box
      w="100%"
      p={5}
      display="block"
      backgroundColor={curr ? "light_gray.250" : "light_gray.200"}
      color={curr ? theme.colors.gray[900] : theme.colors.gray[500]}
    >
      <LinkWithTarget href={route.pathname}>{route.display}</LinkWithTarget>
    </Box>
  );
}

export function NavAuthenticated() {
  const { isOpen, onToggle } = useDisclosure();
  const router = useRouter();
  const { data: account } = useFetchAccount();
  const { data: permissions } = useGetUserPermissions();

  const isEnabled = Boolean(
    account?.is_accounting_enabled && account?.accounting_platform,
  );
  const { data: rutterTransactionsNotYetSynced } =
    useRutterTransactionCountNotYetSynced(isEnabled);

  const isEnabledPMSAccounting = Boolean(
    account?.is_accounting_enabled && account?.pms_name,
  );
  const { data: pmsTransactionsNotYetSynced } =
    usePmsTransactionCountNotYetSynced(isEnabledPMSAccounting);

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const items = useCallback(
    (routes: Route[], isDesktop = false) => (
      <>
        {routes.map((route) => {
          let routeVisibleByEnabledProducts = true;

          if (route.pathname === "/cards") {
            routeVisibleByEnabledProducts =
              isProductEnabledForAccount(Product.CREDIT, account) ||
              isProductEnabledForAccount(Product.TREASURY, account);
          }

          if (
            route.pathname === "/payments" ||
            route.pathname === "/recipients"
          ) {
            routeVisibleByEnabledProducts =
              isProductEnabledForAccount(Product.PAYMENT_LINK, account) ||
              isProductEnabledForAccount(Product.TREASURY, account);
          }

          if (route.pathname === "/accounts") {
            routeVisibleByEnabledProducts =
              isProductEnabledForAccount(Product.TREASURY, account) ||
              isProductEnabledForAccount(Product.CREDIT, account) ||
              isProductEnabledForAccount(Product.PAYMENT_LINK, account);
          }

          if (!routeVisibleByEnabledProducts) {
            return <></>;
          }

          if (
            !isAuthorized(route, permissions, account?.is_accounting_enabled)
          ) {
            return <></>;
          }

          const visibleChildren = route.children
            ? route.children.filter(
                (child) => !child.condition || child.condition(account),
              )
            : [];

          return (
            <>
              <NavLink
                key={route.pathname}
                route={route}
                currentPathname={router.pathname}
                isDesktop={isDesktop}
                expanded={expanded}
                setExpanded={setExpanded}
              />
              {visibleChildren.length > 0 && expanded[route.display] !== false && (
                <Box ml={7} display="flex" flexDirection="column" gap={1}>
                  {visibleChildren.map((child) => {
                    return (
                      <ChildNavLink
                        key={child.pathname}
                        route={child}
                        currentPathname={router.asPath}
                        isDesktop={isDesktop}
                        badge={
                          child.badge
                            ? child.badge({
                                rutterTransactionsNotYetSynced:
                                  rutterTransactionsNotYetSynced?.total ?? -1, // -1 indicates loading
                                pmsTransactionsNotYetSynced:
                                  pmsTransactionsNotYetSynced?.total ?? -1, // -1 indicates loading
                              })
                            : null
                        }
                      />
                    );
                  })}
                </Box>
              )}
            </>
          );
        })}
      </>
    ),
    [
      router.pathname,
      permissions,
      account,
      expanded,
      rutterTransactionsNotYetSynced,
      pmsTransactionsNotYetSynced,
    ],
  );

  return (
    <>
      {/* Mobile */}
      <Box as="nav" w="100%" display={{ base: "block", lg: "none" }}>
        <Flex p={5} justifyContent="space-between" alignItems="center">
          <HostFiLogo />
          <IconButton
            display="flex"
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>

        <Box display="block" bg="light_gray.200">
          <Collapse in={isOpen} animateOpacity>
            {items([...featureRoutes, ...userRoutes], false)}
            <Box as="li" p={5} listStyleType="none">
              <ButtonLogout />
            </Box>
          </Collapse>
        </Box>
      </Box>

      {/* Desktop */}
      <Box
        as="nav"
        bg="light_gray.200"
        borderRight="1px"
        borderRightColor="gray.200"
        display={{ base: "none", lg: "flex" }}
        flexDirection="column"
        h="full"
        w={240}
        position="fixed"
        zIndex={50}
      >
        <Flex h="24" alignItems="center" px={6}>
          <HostFiLogo width="180px" />
        </Flex>

        {account?.name !== "" && (
          <>
            <Flex alignItems="center" px={6} my="10px">
              <Box
                bg="white"
                border="1px solid"
                borderColor="light_gray.400"
                borderRadius="3px"
                mr="7px"
                p="9px"
              >
                <TbBriefcase2
                  size="13px"
                  color={theme.colors.midnight_teal[100]}
                />
              </Box>
              <Text
                fontSize="md"
                fontWeight="semibold"
                color="midnight_teal.400"
              >
                {account?.name}
              </Text>
            </Flex>
            <Divider mb="20px" color="light_gray.400" />
          </>
        )}

        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap={1} px={2}>
            {items(featureRoutes, true)}
          </Box>
        </Flex>
      </Box>
    </>
  );
}
