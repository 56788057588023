import { Auth0ContextInterface, LogoutOptions } from "@auth0/auth0-react";
import { api } from "network";
import { useRouter } from "next/router";
import posthog from "posthog-js";

import { isBrowser, posthogReady } from "./env";

const ACCESS_TOKEN_KEY = "hf:at";

const isAuthenticatedRoute = (route: string) =>
  [
    "/welcome",
    "/signup/terms-and-conditions",
    "/signup/link-external-bank-account",
    "/signup/fund-account",
    "/signup/congrats",
    "/signup/income",
    "/signup/credit-onboarding",
    "/signup-user/terms-and-conditions",
    "/settings",
    "/settings/profile",
    "/settings/account/details",
    "/settings/account/limits",
    "/settings/account/payments",
    "/settings/account/custom-fields",
    "/settings/account/transaction-links",
    "/settings/team",
    "/settings/integrations/accounting",
    "/settings/integrations/property-management",
    "/settings/integrations/receipt-capture",
    "/settings/external-cards",
    "/credit/application",
    "/dashboard",
    "/transactions",
    "/properties",
    "/cards",
    "/payments",
    "/accounts",
    "/integrations",
    "/accounting",
    "/accounting/quickbooks",
    "/accounting/quickbooks_desktop",
    "/accounting/track",
    "/profile",
    "/open-dispute",
    "/documents",
    "/money-movement/deposit",
    "/money-movement/pay",
    "/money-movement/transfer",
  ].includes(route);

export function getAccessToken(): string {
  return (isBrowser && localStorage.getItem(ACCESS_TOKEN_KEY)) || "";
}

export function setAccessToken(token?: string) {
  if (isBrowser && token) localStorage.setItem(ACCESS_TOKEN_KEY, token);
}

export function configureAuthMethods(auth0: Auth0ContextInterface) {
  if (!api.prototype.getAccessTokenSilently) {
    api.prototype.getAccessTokenSilently = auth0.getAccessTokenSilently;
  }

  if (!api.prototype.logout) {
    api.prototype.logout = (options?: LogoutOptions) => {
      auth0.logout(options);
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      if (posthogReady) posthog.reset();
    };
  }
}

export function useRouteRequiresAuth() {
  return isAuthenticatedRoute(useRouter().pathname);
}
